const initialState = {
  workType: {},
  count: 0,
  userInfo: {
    member_levels: {},
    permissions: {}
  },
  permissions: {}
};
export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-userInfo':
      {
        const {
          userInfo = initialState.userInfo,
          userLoading
        } = action.payload;
        return {
          userLoading: userLoading,
          userInfo: userInfo
        };
      }

    case 'update-workType':
      {
        const {
          workType = initialState.workType
        } = action.payload;
        return { ...state,
          workType: workType
        };
      }

    case 'update-permissions':
      {
        const {
          permissions = initialState.permissions
        } = action.payload;
        return { ...state,
          permissions: permissions
        };
      }

    default:
      return state;
  }
}